
class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.id = ''
      this.deep = ''
      this.name = ''
      this.pinyin = ''
      this.pinyinShor = ''
      this.extName = ''
      this.createTime = ''
      this.updateTime = ''
    } else if (type === 'rule') {
      this.id = [
        {
          required: true,
          message: '请输入编号',
          trigger: 'blur',
        },
      ]
      this.deep = [
        {
          required: true,
          message: '请选择层级',
          trigger: 'blur',
        },
      ]
      this.name = [
        {
          required: true,
          message: '请输入名称',
          trigger: 'change',
        },
      ]
      this.pinyin = [
        {
          required: true,
          message: '请输入拼音',
          trigger: 'change',
        },
      ]
      this.pinyinShor = [
        {
          required: true,
          message: '请输入拼音缩写',
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
